"use client";

import { Select } from "antd";
import "./CustomSelect.Style.scss";
import { forwardRef, useState } from "react";
const { Option } = Select;

const MultiSelectOptions = forwardRef(function CustomMultiSelect(
  {
    options = [],
    defaultValue = [],
    placeholder = "Please select",
    register,
    mode = "multiple",
    onSearch,
    loading = false,
    ...rest
  },
  ref
) {
  const [searchValue, setSearchValue] = useState("");

  return (
    <Select
      mode={mode}
      className="select-antd-wrapper"
      allowClear={mode === "multiple" || mode === "tags"}
      style={{
        width: "100%",
      }}
      placeholder={placeholder}
      ref={ref}
      showSearch
      filterOption={
        onSearch
          ? false
          : (input, option) =>
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      dropdownStyle={{
        //zIndex: 1072,
        zIndex: 1200,
      }}
      onSearch={(val) => {
        onSearch(val);
        setSearchValue(val);
      }}
      loading={loading}
      //maxTagCount={5}
      notFoundContent={
        <div style={{ textAlign: "center", padding: "10px" }}>
          <p style={{ color: "#999" }}>
            {searchValue
              ? "No Data"
              : "Type here to find categories or products."}
          </p>
        </div>
      }
      {...rest}
    >
      {options &&
        options.length > 0 &&
        options.map((el, idx) => (
          <Option value={el.value} key={el.value}>
            <span>
              <p className="font-normal">{el.label}</p>
              {el.name && (
                <p className="label__name text-xs font-normal text-primary">
                  {el.name}
                </p>
              )}
            </span>
          </Option>
        ))}
    </Select>
  );
});

export default MultiSelectOptions;
